/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable global-require */

import { useEffect } from 'react'
import { AmplitudeClient } from 'amplitude-js'

import { isClient } from '../platform'

/**
 * Returns if we should track events with Amplitude.
 */
function shouldTrack(): boolean {
  return (
    isClient &&
    (process.env.ENVIRONMENT === 'production' ||
      process.env.ENVIRONMENT === 'staging')
  )
}

const apiKey = process.env.AMPLITUDE_API_KEY || ''
const instance: AmplitudeClient = isClient
  ? require('amplitude-js').getInstance()
  : undefined

/**
 * Logs given text in gray to signified it is just a mocked information.
 *
 * @param text - Text to log.
 * @param rest - Additional parameter passed to the log function.
 */
function log(text: string, ...rest: any[]): void {
  // eslint-disable-next-line no-console
  console.log(`%c ${text}`, 'color: #777', ...rest)
}

/**
 * Use Amplitude hook initializes Amplitude for the application.
 */
function useAmplitude(): void {
  useEffect((): void => {
    if (shouldTrack()) {
      instance.init(apiKey)
    } else {
      log('amplitude: init')
    }
  }, [])
}

/**
 * Tracks a screen view event.
 *
 * @param screenName     - The name of the screen.
 * @param additionalData - Additional data to be passed to the event.
 */
function useTrackSreenView(screenName: string, additionalData?: any): void {
  useEffect((): void => {
    const data = {
      env: process.env.ENVIRONMENT,
      ...additionalData,
    }

    if (shouldTrack()) {
      instance.logEvent(`view ${screenName}`, data)
    } else {
      log(`amplitude: log event [view ${screenName}]`, data)
    }
  }, [screenName, additionalData])
}

export { useAmplitude, useTrackSreenView }
